import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import emptyCircle from '../../assets/images/empty-circle.svg';
import checkGreen from '../../assets/images/green-check.svg';
import redCircle from '../../assets/images/red-circle.svg';

const PasswordTooltip = ({ text, children, showTooltip, touched }) => {
  const containsUppercase = /[A-Z]/.test(text);
  const containsLowercase = /[a-z]/.test(text);
  const containsSymbol = /[^\w\s]/.test(text);
  const containsNumber = /\d/.test(text);

  const checkImage = type => {
    switch (type) {
      case 'first':
        if (text?.length > 7) return checkGreen;
        break;
      case 'second':
        if (containsSymbol || containsNumber) return checkGreen;
        break;
      case 'third':
        if (containsUppercase) return checkGreen;
        break;
      case 'forth':
        if (containsLowercase) return checkGreen;
        break;
      default:
        return null;
    }
    if (touched) {
      return redCircle;
    }
    return emptyCircle;
  };

  return showTooltip ? (
    <div className="relative-position flex-align-self-bottom">
      {children && children}
      <div className="tooltip absolute-position p--8">
        <ul className="tooltip-list">
          <li className="flex-display">
            <img
              alt="circle"
              src={checkImage('first')}
              width={11}
              className="mr--8"
            />{' '}
            8-20 characters
          </li>
          <li className="flex-display">
            <img
              alt="circle"
              src={checkImage('second')}
              width={11}
              className="mr--8"
            />{' '}
            At least 1 number or special character (! @ # &)
          </li>
          <li className="flex-display">
            <img
              alt="circle"
              src={checkImage('third')}
              width={11}
              className="mr--8"
            />{' '}
            At least 1 uppercase letter (A-Z)
          </li>
          <li className="flex-display">
            <img
              alt="circle"
              src={checkImage('forth')}
              width={11}
              className="mr--8"
            />{' '}
            At least 1 lowercase letter (a-z)
          </li>
        </ul>
        <div className="tooltip-triangle absolute-position"></div>
      </div>
    </div>
  ) : null;
};

PasswordTooltip.propTypes = {
  text: PropTypes.string,
  showTooltip: PropTypes.bool,
  children: PropTypes.any
};
export default PasswordTooltip;
