import React, { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useClickOutside from '../../hooks/useClickOutside';

import './notification.scss';

const notificationConfig = {
  type: ['success', 'info', 'warning', 'error'],
  mode: ['default', 'persistent']
};

const Notification = props => {
  const {
    data: {
      id,
      show,
      title,
      message,
      timeout,
      mode = notificationConfig.mode[0],
      type = notificationConfig.type[0]
    },
    onClose,
    onMessageHide
  } = props;

  const [ref, hasClickedOutside] = useClickOutside();

  useEffect(() => {
    if (mode === 'persistent' && hasClickedOutside) {
      onMessageHide(id, 0);
    }
  }, [hasClickedOutside]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mode === 'persistent' && show) return;
    show ? onMessageHide(id, timeout) : onClose(id);
  }, [show, mode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <li className={classnames({ show })} key={id} ref={ref}>
      <div className={`notification-holder t-${type}`}>
        <ul>
          <li>
            <h2>{title || type}</h2>
            {message && <p>{message}</p>}
          </li>
        </ul>
      </div>
    </li>
  );
};

Notification.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.oneOf(notificationConfig.type),
    mode: PropTypes.oneOf(notificationConfig.mode),
    onClose: PropTypes.func,
    timeout: PropTypes.number
  }),
  onMessageHide: PropTypes.func
};

export default Notification;
